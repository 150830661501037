import { useStaticQuery, graphql } from "gatsby"

const homepageVie = graphql`
  {
    allDataYaml(filter: { codePage: { eq: "homepage-vi" } }) {
      edges {
        node {
          ...fragmentHomepage
        }
      }
    }
  }
`

const useHomepageData = () => {
  const data = useStaticQuery(homepageVie)

  if (!data) return null

  return data.allDataYaml.edges[0].node
}

export default useHomepageData
