// https://github.com/gatsbyjs/gatsby/issues/8078

import React from "react"

import Layout from "components/layout"
import useHomepageData from "hooks/queryHomepage.vi"
import ViewHomepage from "components/ViewHomepage"


const IndexPage = props => {
  const { pageContext } = props;
  const pageData = useHomepageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewHomepage pageData={pageData} />
    </Layout>
  )
}

export default IndexPage
